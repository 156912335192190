export default {
    methods: {
        indexColor(color_id) {
            var hex_color = "";
            switch (parseInt(color_id)) {
                case 1:
                    hex_color = "#3bccff";
                    break;
                case 2:
                    hex_color = "#92d050";
                    break;
                case 3:
                    hex_color = "#e8e819";
                    break;
                case 4:
                    hex_color = "#ffa200";
                    break;
                case 5:
                    hex_color = "#ff3b3b";
                    break;

                default:
                    hex_color = "#787878";
                    break;
            }
            return hex_color;
        },
        descriptionAQIIndexFromColor(val) {
            if (val == "1") {
                return this.$t('info.t1_m1')
            } else if (val == "2") {
                return this.$t('info.t1_m2')
            } else if (val == "3") {
                return this.$t('info.t1_m3')
            } else if (val == "4") {
                return this.$t('info.t1_m4')
            } else if (val == "5") {
                return this.$t('info.t1_m5')
            } else {
                return "-";
            }
        },
        colorIdAQIIndex(val) {
            if (val > 0 && val < 26) {
                return 1;
            } else if (val >= 26 && val < 51) {
                return 2;
            } else if (val >= 51 && val < 101) {
                return 3;
            } else if (val >= 101 && val < 201) {
                return 4;
            } else if (val >= 201) {
                return 5;
            } else {
                return 0;
            }
        },
        saveStationDetailID (val) {
            //console.log("save data " , val)
            localStorage.setItem('station_detail_id', val)
        },
        getStationDetailID () {
            return localStorage.getItem('station_detail_id')
        }
    }
}