<template>
    <div>
        <Header/>
        <div class=" v_content">
        <div style="padding:25px;">
            <div class="justify-content-md-center" style="max-width: 150vh;margin: auto;">
                <b-form-select v-model="stationSelect" :options="stationList" @change="changeData()"></b-form-select>
                <div class="s_detail" v-if="stationDetail != null">
                    <h4><strong>{{
                        $i18n.locale === 'th' ? stationDetail.Name : stationDetail.NameEn
                    }}</strong></h4>
                    <p>{{ $i18n.locale === 'th' ? "จากผลการตรวจวัดคุณภาพอากาศในพื้นที่ " + stationDetail.Name : "from the results of air quality measurements in the area " + stationDetail.NameEn }}
                        {{ drawTextFromAQI() }}
                    </p>
                </div>
                <div v-else>
                    Loading
                </div>
                <div style="margin-top:20px;">
                    <b-row style="">
                        <b-col cols="12" sm="12" md="12" lg="12" xl="5">
                            <div style="text-align: left;">
                                <strong>{{ $i18n.locale === 'th' ? 'กราฟแสดงข้อมูลย้อนหลัง 7 วัน' : 'Graph showing past 7 days data' }}</strong>
                            </div>
                            <div style="margin-top:15px;">
                                <b-tabs content-class="mt-3">
                                    <b-tab title="AQI" active>
                                        <Highcharts ref="highchartsRef" :options="chartOptionAQI">
                                        </Highcharts>
                                    </b-tab>
                                    <b-tab>
                                        <template #title>
                                            PM<sub>2.5</sub>
                                        </template>
                                        <Highcharts ref="highchartsRef" :options="chartOptionPM25">
                                        </Highcharts>
                                    </b-tab>
                                    <b-tab>
                                        <template #title>
                                            PM<sub>10</sub>
                                        </template>
                                        <Highcharts ref="highchartsRef" :options="chartOptionPM10">
                                        </Highcharts>
                                    </b-tab>
                                    <b-tab title="CO">
                                        <Highcharts ref="highchartsRef" :options="chartOptionCO">
                                        </Highcharts>
                                    </b-tab>
                                    <b-tab>
                                        <template #title>
                                            O<sub>3</sub>
                                        </template>
                                        <Highcharts ref="highchartsRef" :options="chartOptionO3">
                                        </Highcharts>
                                    </b-tab>
                                    <b-tab>
                                        <template #title>
                                            NO<sub>2</sub>
                                        </template>
                                        <Highcharts ref="highchartsRef" :options="chartOptionNO2">
                                        </Highcharts>
                                    </b-tab>
                                    <b-tab>
                                        <template #title>
                                            SO<sub>2</sub>
                                        </template>
                                        <Highcharts ref="highchartsRef" :options="chartOptionSO2">
                                        </Highcharts>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="12" md="12" lg="12" xl="7">
                            <div style="text-align: left;">
                                <strong>{{ $i18n.locale === 'th' ? 'ตารางแสดงข้อมูลย้อนหลัง 7 วัน' : 'Table showing data for the past 7 days' }}</strong>
                            </div>
                            <b-table 
                            stacked="md"
                            striped 
                            bordered 
                            :items="stationDatas" :fields="fields" style="margin-top:15px;"
                            >
                                <template v-slot:cell(DATETIMEDATA)="data">
                                    {{data.item['DATETIMEDATA'] | moment("ll")}}
                                </template>
                                <template v-slot:cell(PM25)="data">
                                    {{data.item['PM25'] != '-1' && data.item['PM25'] != '-999' ? data.item['PM25'] : '-'}}
                                </template>
                                <template v-slot:cell(PM10)="data">
                                    {{data.item['PM10'] != '-1' && data.item['PM10'] != '-999' ? data.item['PM10'] : '-'}}
                                </template>
                                <template v-slot:cell(CO)="data">
                                    {{data.item['CO'] != '-1' && data.item['CO'] != '-999' ? data.item['CO'] : '-'}}
                                </template>
                                <template v-slot:cell(O3)="data">
                                    {{data.item['O3'] != '-1' && data.item['O3'] != '-999' ? data.item['O3'] : '-'}}
                                </template>
                                <template v-slot:cell(SO2)="data">
                                    {{data.item['SO2'] != '-1' && data.item['SO2'] != '-999' ? data.item['SO2'] : '-'}}
                                </template>
                                <template v-slot:cell(NO2)="data">
                                    {{data.item['NO2'] != '-1' && data.item['NO2'] != '-999' ? data.item['NO2'] : '-'}}
                                </template>
                                <template v-slot:cell(AQI)="data">
                                    {{data.item['AQI'] != '-1' && data.item['AQI'] != '-999' ? data.item['AQI'] : '-'}}
                                </template>
                                <template v-slot:cell(sum)="data">
                                    <div class="dot" :style="colorBox(data.item['AQI'])">
                                        {{colorText(data.item['AQI'])}}
                                    </div>
                                </template>
                                <!-- <template #head()="data">
                                    <small v-html="data.label" style="vertical-align: middle;"></small>
                                </template> -->
                            </b-table>
                        </b-col>
                    </b-row>
                </div>
                <div style="padding:20px;">
                    <b-row class="map_bar">
                        <b-col>
                            {{$t('statusbar.verygood')}}
                        </b-col>
                        <b-col>
                            {{$t('statusbar.good')}}
                        </b-col>
                        <b-col>
                            {{$t('statusbar.moderate')}}
                        </b-col>
                        <b-col>
                            {{$t('statusbar.startaffect')}}
                        </b-col>
                        <b-col>
                            {{$t('statusbar.affecthealth')}}
                        </b-col>
                    </b-row>
                    <b-row style="padding-top:10px;padding-bottom:40px;padding-left:10px;padding-right:10px;">
                        <b-col style="padding:0px;">
                            <div style="height:20px;background: #3BCCFF;border-top-left-radius: 10px;border-bottom-left-radius: 10px;"></div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:20px;background: #92D050;"></div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:20px;background: #FFFF00;"></div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:20px;background: #FFA200;"></div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:20px;background: #F04646;border-top-right-radius: 10px;border-bottom-right-radius: 10px;"></div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import moment from 'moment'
import axios from 'axios'
import main_function from '../../mixins/main_funxtion'

export default {
    name : 'station_detail',
    mixins: [main_function],
    components : {
        Header,
        Footer
    },
    data () {
        return {
            stationSelect:'02t',
            stationList:[],
            stationDetail:null,
            stationDatas:[],
            fields : [
                { key: 'DATETIMEDATA', label: 'Date' },
                { key: 'PM25', label: 'PM₂₅\n(ug/m³)\nAvg 24Hr'},
                { key: 'PM10', label: 'PM₁₀\n(ug/m³)\nAvg 24Hr' },
                { key: 'CO', label: 'CO\n(ppm)\nAvg 8Hr' },
                { key: 'O3', label: 'O₃\n(ppm)\nAvg 8Hr' },
                { key: 'SO2', label: 'SO₂\n(ppb)' },
                { key: 'NO2', label: 'NO₂\n(ppb)' },
                { key: 'AQI', label: 'AQI' },
                { key: 'sum', label: 'air quality' }
            ],
            chartOptionAQI: {
                chart: {
                    type: 'spline'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: [{
                    name: 'AQI',
                    data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
                }]
            },
            chartOptionPM25: {
                chart: {
                    type: 'spline'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        useHTML:true,
                        text: 'ug/m<sup>3</sup>'
                    },
                    plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                    }]
                },
                tooltip: {
                    useHTML:true,
                    valueSuffix: 'ug/m<sup>3</sup>'
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: [{
                    name: 'PM<sub>2.5</sub>',
                    data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
                }]
            },
            chartOptionPM10: {
                chart: {
                    type: 'spline'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        useHTML:true,
                        text: 'ug/m<sup>3</sup>'
                    },
                    plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                    }]
                },
                tooltip: {
                    useHTML:true,
                    valueSuffix: 'ug/m<sup>3</sup>'
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: [{
                    name: 'PM<sub>10</sub>',
                    data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
                }]
            },
            chartOptionCO: {
                chart: {
                    type: 'spline'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        useHTML:true,
                        text: 'ppm'
                    },
                    plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                    }]
                },
                tooltip: {
                    useHTML:true,
                    valueSuffix: 'ppm'
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: [{
                    name: 'CO',
                    data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
                }]
            },
            chartOptionO3: {
                chart: {
                    type: 'spline'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        useHTML:true,
                        text: 'ppm'
                    },
                    plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                    }]
                },
                tooltip: {
                    useHTML:true,
                    valueSuffix: 'ppm'
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: [{
                    name: 'O3',
                    data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
                }]
            },
            chartOptionNO2: {
                chart: {
                    type: 'spline'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        useHTML:true,
                        text: 'ppb'
                    },
                    plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                    }]
                },
                tooltip: {
                    useHTML:true,
                    valueSuffix: 'ppb'
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: [{
                    name: 'NO2',
                    data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
                }]
            },
            chartOptionSO2: {
                chart: {
                    type: 'spline'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        useHTML:true,
                        text: 'ppb'
                    },
                    plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                    }]
                },
                tooltip: {
                    useHTML:true,
                    valueSuffix: 'ppb'
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: [{
                    name: 'SO2',
                    data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
                }]
            }
        }
    },
    methods : {
        colorBox(aqi){
            var color_num = this.colorIdAQIIndex(aqi)
            return "background-color:" + this.indexColor(color_num)
        },
        colorText(aqi){
            var color_num = this.colorIdAQIIndex(aqi)
            return this.descriptionAQIIndexFromColor(color_num)
        },
        changeData(){
            this.getStationDatas()
        },
        drawTextFromAQI(){
            if(this.stationDatas.length > 0){
                return ''
            }

            return '-'
        },
        getStationDatas(){
            this.stationDatas = []
            axios.get('http://air4thai.com/forweb/getStationData.php?stationID=' + this.stationSelect,{}).then((response) => {
                //console.log('getStationDatas',response)
                if (response.status == 200) {
                    this.stationDatas = response.data
                    var dateData = []
                    var aqiData = []
                    var pm25Data = []
                    var pm10Data = []
                    var coData = []
                    var o3Data = []
                    var no2Data = []
                    var so2Data = []
                    for(var a=(this.stationDatas.length - 1);a>=0;a--){
                        dateData.push(moment(this.stationDatas[a].DATETIMEDATA).format('DD MMM'))
                        if(parseFloat(this.stationDatas[a].AQI) >= 0)
                            aqiData.push(parseFloat(this.stationDatas[a].AQI))
                        else
                            aqiData.push(null)
                        if(parseFloat(this.stationDatas[a].PM25) >= 0)
                            pm25Data.push(parseFloat(this.stationDatas[a].PM25))
                        else
                            pm25Data.push(null)
                        if(parseFloat(this.stationDatas[a].PM10) >= 0)
                            pm10Data.push(parseFloat(this.stationDatas[a].PM10))
                        else
                            pm10Data.push(null)
                        if(parseFloat(this.stationDatas[a].CO) >= 0)
                            coData.push(parseFloat(this.stationDatas[a].CO))
                        else
                            coData.push(null)
                        if(parseFloat(this.stationDatas[a].O3) >= 0)
                            o3Data.push(parseFloat(this.stationDatas[a].O3))
                        else
                            o3Data.push(null)
                        if(parseFloat(this.stationDatas[a].NO2) >= 0)
                            no2Data.push(parseFloat(this.stationDatas[a].NO2))
                        else
                            no2Data.push(null)
                        if(parseFloat(this.stationDatas[a].SO2) >= 0)
                            so2Data.push(parseFloat(this.stationDatas[a].SO2))
                        else
                            so2Data.push(null)
                    }

                    this.chartOptionAQI.xAxis['categories'] = dateData
                    this.chartOptionPM25.xAxis['categories'] = dateData
                    this.chartOptionPM10.xAxis['categories'] = dateData
                    this.chartOptionCO.xAxis['categories'] = dateData
                    this.chartOptionO3.xAxis['categories'] = dateData
                    this.chartOptionNO2.xAxis['categories'] = dateData
                    this.chartOptionSO2.xAxis['categories'] = dateData

                    this.chartOptionAQI.series[0]['data'] = aqiData
                    this.chartOptionPM25.series[0]['data'] = pm25Data
                    this.chartOptionPM10.series[0]['data'] = pm10Data
                    this.chartOptionCO.series[0]['data'] = coData
                    this.chartOptionO3.series[0]['data'] = o3Data
                    this.chartOptionNO2.series[0]['data'] = no2Data
                    this.chartOptionSO2.series[0]['data'] = so2Data
                }
            }).catch(err => {
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: err,
                    icon: 'error'
                })
            })
        },
        getStationList(){
            axios.get('http://air4thai.com/forweb/getHistoryStation.php',{}).then((response) => {
                //console.log('list',response)
                var have = response.data.length
                this.stationList = []
                this.stationDetail = null
                for(var a=0;a<have;a++){
                    if(response.data[a].ID == this.stationSelect){
                        this.stationDetail = response.data[a]
                    }
                    if(response.data[a].Name == null){
                        console.log("Null Name " , response.data[a].ID)
                    }
                    this.stationList.push({
                        value: response.data[a].ID , text: response.data[a].ID + " " + response.data[a].Name
                    })
                }
                this.getStationDatas()
                //console.log("select",this.stationSelect)
                // this.stations = response.data.stations
                
            }).catch(err => {
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: err,
                    icon: 'error'
                })
            })
        }
    },
    created() {
        
    },
    mounted() {
        // console.log('params',this.$route.params.sid)
        this.stationSelect = this.$route.params.sid
        if(this.stationSelect != null){
            this.saveStationDetailID(this.stationSelect)
        }else{
            this.stationSelect = this.getStationDetailID()
        }
        this.getStationList()
    }
}
</script>

<style lang="scss" scoped>
.s_detail{
    margin-top: 20px;
    text-align: left;
}
.map_bar{
    text-align: center;
    margin-top:30px;
    font-size: max(1.2vw, 12px);
}
.dot {
  padding:2px;
  border-radius: 5px;
}
</style>